import * as React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './App.css';
import App from './App';
import { db } from './db/db';
import { multiX } from "./components/otros/dominios";
import pako from 'pako';
import { getDestinos } from './functions/patagoniaApi';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

function getTimestamp() {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return date + ' ' + time;
}

const root = createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

function decryptDataFromPhp(str) {
    try {
        // $jsonData = strtr($jsonString, ‘-_‘, ‘+/‘);
        var b64DataNoSpaces = str.replace(/ /g, "+");
        b64DataNoSpaces = b64DataNoSpaces.replace(/-/g, "+")
        b64DataNoSpaces = b64DataNoSpaces.replace(/_/g, "/")
        var strData = atob(b64DataNoSpaces);
        var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
        var binData = new Uint8Array(charData);
        var data = pako.inflateRaw(binData);
        return String.fromCharCode.apply(null, new Uint16Array(data));
    } catch (exception) {
        console.log(exception);
        return false;
    }
}


const requestPendientes = async () => {

    console.log("Executing requestPendientes");

    try {
        // Fetch data from IndexedDB using Dexie.js
        const pendientes = await db.requestPendientes.toArray();
        console.log(pendientes);

        // Create an array of promises for the HTTP requests
        const requestPromises = pendientes.map(async (c) => {

            let g = /gaDB=([^&]+)/;
            let tim = /timestamp=([^&]+)/;
            let coords = /coordenadas=([^&]+)/;

            let gaDB = g.exec(c.post);
            let matchTim = tim.exec(c.post);
            let matchCoords = coords.exec(c.post);

            if (gaDB && gaDB !== null) {

                await fetch(c.url, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        // 'Content-Type': 'application/json'
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    crossDomain: true,
                    body: c.post
                })
                    .then(result => result.json())
                    .then(result => {
                        if (result.error === false) {
                            db.requestPendientes.delete(c.id)
                            console.log(result)
                            let guias = JSON.parse(result.guias)
                            console.log(guias)

                            guias.map(d => {

                                let guiaData = {};
                                let items = [];

                                if (d.m_items.length > 0) {
                                    for (let i = 0; i < d.m_items.length; i++) {
                                        items.push({
                                            'categoria_id': d.m_items[i].c,
                                            'cantidad': d.m_items[i].q,
                                            'material': d.m_items[i].d,
                                            'precio': d.m_items[i].p
                                        });
                                    }
                                    guiaData.items = items;
                                }

                                guiaData.domain = multiX.domain;
                                guiaData.key = multiX.key
                                guiaData.numero = d.e_num;
                                guiaData.rut = d.e_rut;
                                guiaData._id = guiaData.rut + '-' + guiaData.numero;
                                guiaData.received_at = matchTim[1];
                                guiaData.received_coords = matchCoords[1];
                                guiaData.completed = 1;
                                guiaData.saved = 0;
                                guiaData.delivered = 0;
                                guiaData.fecha = d.e_fecha
                                guiaData.img1 = ''
                                guiaData.img2 = ''
                                guiaData.img3 = ''
                                guiaData.obs = ''
                                guiaData.destino = d.m_dest
                                guiaData.i = JSON.stringify(d.m_items)
                                guiaData.ga = d.ga

                                console.log(guiaData)

                                db.guiaDespacho.add({
                                    id: guiaData.numero,
                                    guia: guiaData
                                });

                            })
                        } else {
                            console.log('No se ha podido entregar la notificación')
                        }
                    })

            } else {
                await fetch(c.url, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    crossDomain: true,
                    body: c.post,
                })
                    .then(res => res.json())
                    .then(res => {

                        if (res.error === false) {
                            db.requestPendientes.delete(c.id)

                        } else {
                            console.log('No se ha podido entregar la notificación')
                        }
                    })


            }
        });



    } catch (error) {
        console.error('Error:', error);
    }
};


const getDestinosData = async () => {


    try {

        getDestinos()
        .then( res => {
            console.log(res.data)
            // db.destinos.clear()
            db.destinos.put({id:1,unidades:res.data.unidades})
    
        })
        .catch( err => console.log(err))

        
    } catch (error) {
        console.error('Error:', error);
    }
}



setInterval(requestPendientes, 60000);
setInterval(getDestinosData, 24*60*60000);