import Dexie from 'dexie';

export const db = new Dexie('tracking');
db.version(5).stores({
  identidades: '++id, rut, unidad', 
  guiaDespacho:'++id,  guia',
  requestPendientes:'++id,  guiasPendientes',
  destinos:'++id,  unidades'
});

//  guiaDespachoEntregadas:'++id,  guiaEntregada',