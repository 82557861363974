import React,{useEffect, useState, useCallback} from 'react'
import { useGeolocated } from "react-geolocated";
import { useIsOnline } from 'react-use-is-online';
import { useLiveQuery } from 'dexie-react-hooks'
import { Link } from 'react-router-dom'
import { db } from '../db/db'


import Header from '../components/nav/Header'
import BottomMenu from '../components/nav/BottomMenu';

import { home } from '../components/otros/textos'

const Home = () => {



 
    const { isOnline, isOffline, error } = useIsOnline();





   

    const guias = useLiveQuery(
      () => db.guiaDespacho.toArray()
    );

    return (
        < >
          <Header />
          <div className='container p-4'>
            <h6 >
            <i class="fa-solid fa-truck"></i>  {home.titulo}
            </h6>
            <hr/>
                <Link to='/carga/recibir-guia-despacho' value="RECIBIR"  style={{width:'100%'}} class="btn btn-recibir py-3 mb-3">{home.boton1}</Link>
                <Link to='/carga/seleccionar-stock' value="ENTREGAR"  style={{width:'100%'}} class="btn btn-entrega py-3 mt-3">{home.boton2} {'('}{guias && guias.length > 0 ? guias.length : '0'}{')'}</Link>
            <hr/>
          
          </div>

          <BottomMenu />
        </>
    )
}

export default Home
