import axios from "axios";
import { multiX } from "../components/otros/dominios";


export const getDestinos = async() =>{
    return await axios.get(`https://${multiX.domain}/sync/unidades/8d368a8b-4bd2-4d88-9e82-ac567a3fc6a0`,{
        headers:{
            "Content-Type":"text/html; charset=UTF-8",
            Accept:'*/*'
        }
    })
}


export const sendSOData = async(data) =>{
    return await axios.post(`https://${multiX.domain}/sync/notificar/INFORMACION`,data,{
        headers:{
            "Content-Type":"application/x-www-form-urlencoded",
            Accept:'*/*'
        }
    })
}