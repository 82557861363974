import React from 'react'
import Header from '../components/nav/Header'
import BottomMenu from '../components/nav/BottomMenu';




import { info } from '../components/otros/textos'
import { version,fechaUltAct } from '../components/otros/dominios';
import { browserName,mobileVendor,mobileModel, osVersion, osName,engineName,engineVersion } from 'react-device-detect';

const ErrorModelo = () => {
  return (
    <>
      
    <Header />
    
    <div className='container p-4' >
      
      <h6 >
      <i class="fa-solid fa-info"></i> {info.titulo}
      </h6>
      <p className='infoTitle'><i class="fa-solid fa-circle-info"></i> El dispositivo utilizado tiene instalada una versión desactualizada del sistema operativo y no es compatible con el escaneo de QR.</p>
      <p style={{fontWeight:'bold'}}>Información de Logpro</p>
      <p>Versión : { version }</p>
      <p>Última actualización : {fechaUltAct}</p>
      <p style={{fontWeight:'bold'}}>Información de {osName}</p>
      <p className='buttonS'>Actualice su sistema operativo <i><b>iOS</b></i> a lo menos a la versión 15.1</p>
      <div>
      <table className='table-list'>
        <tr>
          <td>Navegador</td>
          <td>{browserName}</td>
        </tr>
        <tr>
          <td>Marca</td>
          <td>{mobileVendor}</td>
        </tr>
        <tr>
          <td>Modelo</td>
          <td>{mobileModel}</td>
        </tr>
        <tr>
          <td>SO</td>
          <td>{osName}</td>
        </tr>
        <tr>
          <td>Versión</td>
          <td>{osVersion}</td>
        </tr>
      </table>
      </div>

    </div>


    <BottomMenu />
  
    
    
    </>

    
  )
}

export default ErrorModelo