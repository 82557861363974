import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../db/db'



import { browserName,mobileVendor,mobileModel, osVersion, osName,engineName,engineVersion } from 'react-device-detect';

const UserRoute =  ({Component}) =>{

    let navigate = useNavigate()

    const identidad = useLiveQuery(
        () => db.identidades.toArray()
    );

    console.log(parseFloat(osVersion))

    return identidad && identidad.length  > 0   ? 
        (
            (osName === 'iOS')  ? 
            (
                parseFloat(osVersion) < 15.1 ? (
                    navigate('/update-telefono')
                ):(
                    <Component/>
                )
            ) : (
                <Component/>
            )
        )
        :(
            navigate('/identificacion')
        )
    
    
    
    

    //   return identidad && identidad.length > 0 ? 
    // (
    // <Component/>
    // ) : (
    //     navigate('/identificacion')
    // )
}


export default UserRoute