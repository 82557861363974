import React from 'react'



import Header from '../components/nav/Header';
import BottomMenu from '../components/nav/BottomMenu';
import { multiX } from "../components/otros/dominios";
import { info } from '../components/otros/textos';
import { version,fechaUltAct } from '../components/otros/dominios';
import { browserName,mobileVendor,mobileModel, osVersion, osName } from 'react-device-detect';

const Info = () => {
  return (
      <>
      
      <Header />
      <div className='container p-4' >
      
      <h6 >
      <i class="fa-solid fa-info"></i> {info.titulo}
      </h6>
      <p style={{fontWeight:'bold'}}>Información de Logpro</p>
      <p>Versión : { version }</p>
      <p>Última actualización : {fechaUltAct}</p>
      <p style={{fontWeight:'bold'}}>Información de {osName}</p>
      <div>
      <table className='table-list'>
        <tr>
          <td>Navegador</td>
          <td>{browserName}</td>
        </tr>
        <tr>
          <td>Marca</td>
          <td>{mobileVendor}</td>
        </tr>
        <tr>
          <td>Modelo</td>
          <td>{mobileModel}</td>
        </tr>
        <tr>
          <td>SO</td>
          <td>{osName}</td>
        </tr>
        <tr>
          <td>Versión</td>
          <td>{osVersion}</td>
        </tr>
        <tr>
          <td>Tipo</td>
          <td>{multiX.tipo}</td>
        </tr>
      </table>
      </div>
    </div>


      <BottomMenu />
    
      
      
      </>
  )
}

export default Info