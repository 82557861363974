import React from 'react'
import {useNavigate, Link, useLocation } from 'react-router-dom'
import { useGeolocated } from "react-geolocated";
import { useIsOnline } from 'react-use-is-online';


const BottomMenu = () => {

 
  const { isOnline, isOffline, error } = useIsOnline();

  let navigate = useNavigate()
  let location = useLocation()

  
  const {
    coords,
    getLocation,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    enableHighAccuracy: true,
    timeout: Infinity,
    maximumAge: Infinity,
    watch: false,
    suppressLocationOnMount: true,
  });
  
  const nav = () =>{
    let ruta = location.pathname
    console.log(ruta)

    let redirect = ruta.split('/').slice(1)
    
    console.log(redirect)
    if( redirect[0] === 'stock' ){
      navigate('/carga/seleccionar-stock')
    } else{
      navigate('/')
    }
  }

  return (
    <div className='navSubmenu fixed-bottom'>

      <div class="btn-group" role="group"  style={{width:'100%'}} aria-label="Basic example">
          <button onClick={nav}  style={{width:'100%',marginLeft:'5px'}} class="btn btn-submenu"><i class="fa-solid fa-arrow-left"></i></button>
          {!isGeolocationEnabled ? ( <Link to='/gps'  style={{width:'100%'}} class="btn btn-submenu"><i class="fa-solid fa-location-dot"></i></Link>):(<Link to='/gps' style={{width:'100%'}} class="btn btn-submenuSuccess"><i class="fa-solid fa-location-dot"></i></Link>)}
          {isOnline ? <button style={{width:'100%'}} class="btn btn-submenuSuccess"><i class="fa-solid fa-signal"></i></button> : <button  style={{width:'100%'}} class="btn btn-submenu"><i class="fa-solid fa-signal"></i></button>}
          <Link to='/info'  style={{width:'100%'}} class="btn btn-submenu"><i class="fa-solid fa-info"></i></Link>
      </div>
    </div>
  )
}

export default BottomMenu