import React from 'react'
import Logo from '../../img/logo-logpro-blanco.png'
import { Link } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../db/db'

const Header = () => {



    const identidad = useLiveQuery(
        () => db.identidades.toArray()
    );

    // Patente solo letra y numero 
    // Cambiar identidad

    // Geolocalización


  return (
    <div className='navigationMonitor'>
        <div className='left'>
            <img src={Logo} class="mx-auto" style={{width: '5em'}} alt="Logotipo Logpro"/>
        </div>
        <div className='right'>
            { identidad && identidad.length > 0 ? (
                <Link to='/identificacion' type="button" value="IDENTIFICACION"   class="btn btn-id"><i  class="fa-solid fa-id-badge"></i> {identidad[0].idTransporte} / {identidad[0].rutPiloto} </Link>
            ):(
                <Link to='/' type="button" value="IDENTIFICACION"   class="btn btn-id"><i  class="fa-solid fa-id-badge"></i> </Link>
            )}
        </div>
    </div>
  )



}

export default Header