import { Html5QrcodeScanner } from "html5-qrcode";
import React from "react";

const qrcodeRegionId = "html5qr-code-full-region";

class Html5QrcodePlugin extends React.Component {
  render() {
    return <div id={qrcodeRegionId} />;
  }

  componentWillUnmount() {
    // TODO(mebjas): See if there is a better way to handle
    //  promise in `componentWillUnmount`.
    this.html5QrcodeScanner.clear().catch((error) => {
      console.error("Failed to clear html5QrcodeScanner. ", error);
    });
  }

  componentDidMount() {
    var verbose = this.props.verbose === true;
    function createConfig(props) {
      var config = {};
      if (props.fps) {
        config.fps = 15;
      }

      if (props.useBarCodeDetectorIfSupported) {
        config.useBarCodeDetectorIfSupported = true;
      }
      if (props.showZoomSliderIfSupported) {
        config.showZoomSliderIfSupported = true;
      }
      if (props.defaultZoomValueIfSupported) {
        config.defaultZoomValueIfSupported = 2;
      }
      if (props.rememberLastUsedCamera) {
        config.rememberLastUsedCamera = true;
      }
      if (props.showTorchButtonIfSupported) {
        config.showTorchButtonIfSupported = true;
      }
      if (props.facingMode) {
        config.facingMode = { facingMode: { exact: "environment" } };
      }
      return config;
    }

    var config = createConfig(this.props);

    console.log({ config: config });
    // Suceess callback is required.
    if (!this.props.qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback.";
    }

    this.html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );
    // {
    //   useBarCodeDetectorIfSupported: true,
    //   willReadFrequently: true,
    //   showZoomSliderIfSupported: true,
    //   defaultZoomValueIfSupported: 2,
    //   rememberLastUsedCamera: true,
    //   showTorchButtonIfSupported: true,
    //   fps: 15,
    //   facingMode: { facingMode: { exact: "environment" } },
    // }
    //   verbose
    // );
    this.html5QrcodeScanner.render(
      this.props.qrCodeSuccessCallback,
      this.props.qrCodeErrorCallback
    );
  }
}

export default Html5QrcodePlugin;
