import './App.css';
import React, { Fragment, useEffect } from 'react';
import { Routes ,Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';


import Home from './pages/Home'
import Identificacion from './pages/Identificacion';
import Info from './pages/Info'
import GPS from './pages/GPS'
import Carga from './pages/Carga'
import Stock from './pages/Stock'
import StockDetalle from './pages/StockDetalle';



import UserRoute from './components/routes/UserRoute';
import ErrorModelo from './pages/ErrorModelo';

function App() {
  return (
    
    <Fragment>
      <ToastContainer />
      <Routes>
        <Route exact path='/' element={<UserRoute Component={Home}/>} />
        <Route exact path='/identificacion' element={<Identificacion/>} />
        <Route exact path='/info' element={<UserRoute Component={Info}/>} />
        <Route exact path='/gps' element={<UserRoute Component={GPS}/>} />
        <Route exact path='/carga/:tipoCarga' element={<UserRoute Component={Carga}/>} />
        <Route exact path='/stock' element={<UserRoute Component={Stock}/>} />
        <Route exact path='/stock/:id' element={<UserRoute Component={StockDetalle}/>} />
        <Route exact path='/update-telefono' element={<ErrorModelo/>} />
      </Routes>
    </Fragment>
  )
}

export default App;