export const multiX = {
  id: 2,
  domain: "mlog.multi-xsalmon.com",
  key: "8d368a8b-4bd2-4d88-9e82-ac567a3fc6a0",
  rut: "798911600",
  sociedad: "Multi X",
  tipo: "MULTIX",
};

export const version = "2.2.1";
export const fechaUltAct = "06/05/2024";
