export const identificacion = {
  titulo: "IDENTIFICACIÓN",
  subtitulo: "Tipo de transporte",
  checkbox1: "Camión",
  checkbox2: "Nave",
  checkbox3: "Bodega",
  label_1: "Placa patente",
  label_2: "RUT conductor",
  label_3: "Señal de Llamada",
  label_4: "RUT capitán",
  label_5: "Código SAP",
  label_6: "RUT",
  boton: "GUARDAR",
};

export const gps = {
  titulo: "GEOLOCALIZACIÓN",
  texto1: "Tu navegador no admite Geolocalización",
  texto2: "Habilitar geolocalización",
  texto3: "Tu ubicación actual:",
  texto4:
    "La geolocalización es muy importante para el seguimiento de la carga y la gestión logística. Necesitamos conocer tu ubicación cuando recibas y entregues la carga.",
  texto5: "Es importante que habilites la geolocalización en tu navegador:",
};

export const info = {
  titulo: "AYUDA",
  texto1: "Puedes ver cómo usar esta App en",
  texto2:
    "Si tienes dudas, puedes revisar los instructivos que presentamos a continuación:",
  texto3: "Cómo instalar la aplicación",
  texto4: "Activar el seguimiento",
  texto5: "Registrar identificación",
  texto6: "Recepción de carga con Orden de Transporte",
  texto7: "Recepción de carga con Guía de Despacho",
  texto8: "Carga de datos de Guía de Despacho",
  texto9: "Entrega de carga",
};

export const home = {
  titulo: "TRANSPORTE DE CARGA",
  boton1: "RECIBIR CARGA",
  boton2: "ENTREGAR CARGA",
  boton3: "STOCK",
};

export const stock = {
  titulo: "GUÍAS EN STOCK",
  columna1: "RUT",
  columna2: "NRO DOCUMENTO",
};

export const stock_detail = {
  titulo: "Detalle guía",
  nro: "Nº",
  rut: "RUT",
  columna1: "CANT",
  columna2: "MATERIAL",
};

export const recibir = {
  titulo: "RECIBIR CARGA",
  subtitulo:
    "Registra la carga que estás trasladando a través de la lectura de códigos QR.",
  boton1: "Guía de despacho con QR",
  boton2: "Resumen de despacho con QR",
  boton3: "Recibir entrega de otro dispositivo",
};

export const leer_qr = {
  titulo: "Recepción con código QR",
  subtitulo: "Recibir QR.",
};

export const documento_en_stock = {
  titulo: "Documento capturado",
  titulo2:
    "¡Atención! Este documento ya fue procesado y no puede volver a recibirse.",
  texto1: "Si deseas entregar la carga, vuelve al menú principal y selecciona",
  texto2: "entregar carga",
};

export const qr_generado = {
  titulo: "ENTREGAR CARGA",
  subtitulo:
    "Registra la carga que estás trasladando a través de la lectura de códigos QR.",
  boton1: "Entregar más guías",
  boton2: "Ir al menú principal",
};

export const entregar = {
  titulo: "ENTREGAR CARGA",
  subtitulo:
    "Registra la carga que estás trasladando a través de la lectura de códigos QR.",
  boton1: "Seleccionar del stock",
};

export const recibir_guia_despacho = {
  titulo: "Recepción con código QR",
  subtitulo: "Captura el código QR de la guía de despacho.",
};

export const guia_scanned = {
  titulo: "Guía capturada",
  item1: "Nº",
  item2: "RUT Emisor",
  columna1: "Cant",
  columna2: "Material",
  boton1: "RECIBIR GUÍA",
};

export const guia_scanned_update = {
  titulo: "Orden capturada",
  item1: "Nº",
  item2: "Nº",
  columna1: "Cant",
  columna2: "Material",
  boton1: "RECIBIR GUÍA DE DESPACHO",
};

export const seleccionar_stock = {
  titulo: "Registrar entrega",
  subtitulo:
    "Presiona sobre la fila de la Guía que deseas entregar para seleccionarla",
  subtitulo2:
    "***Se podrán seleccionar un máximo de 5 guías para entregar a la vez.",
  columna1: "RUT",
  columna2: "Guía",
  boton1: "Entregar seleccionadas",
};

export const enviar_guias = {
  titulo: "Registrar entrega",
  subtitulo:
    "Registra los datos de quien recibe las siguientes guías de despacho:",
  subtitulo2: "Seleccionadas: ",
  columna1: "RUT",
  columna2: "Guía",
  label1: "Nombre de quien recibe",
  label2: "RUT de quien recibe",
  boton1: "Registrar entrega",
};
