import React,{useEffect, useState, useCallback} from 'react'
import {useNavigate, useParams, Link } from 'react-router-dom'
import Header from '../components/nav/Header';
import BottomMenu from '../components/nav/BottomMenu';
import { db } from '../db/db'

import { stock_detail } from '../components/otros/textos';

const StockDetalle = () => {

    const [guia, setGuia] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchGuia = async () => {
        const data = await db.guiaDespacho.get(id);
        setGuia(data);
      };
      fetchGuia();
    }, [id]);

    let items = guia && guia.guia.items

  return (
    <>
    
    <Header />
    
    <div className='container p-4'>
    <h6 >
                      <i class="fa-solid fa-truck"></i>  {stock_detail.titulo}
                      </h6>
                      <table class="table table-bordered table-striped">
        <tbody>
            <tr>
                <td class="p-1">{stock_detail.nro}</td><td class="p-1"><strong>{ guia && guia.guia.numero }</strong></td>
                <td class="p-1">{stock_detail.rut}</td><td class="p-1"><strong>{ guia && guia.guia.rut }</strong></td>
            </tr>
        </tbody>
    </table>
    <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th class="text-end p-1">{stock_detail.columna1}</th>
                    <th class="p-1">{stock_detail.columna2}</th>
                </tr>
            </thead>
            <tbody>
                    {
                        items && items.map( c => (

                        <tr>
                            <td>{c.cantidad}</td>
                            <td>{c.material}</td>
                        
                        </tr>
                    ))
                    }
                
                
        </tbody>
        </table>

    </div>
    
    

    <BottomMenu/>
    </>
  )
}

export default StockDetalle