import React, { useEffect,useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGeolocated } from "react-geolocated";
import { BrowserView,browserName, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useIsOnline } from 'react-use-is-online';

import { gps } from '../components/otros/textos'

import Header from '../components/nav/Header'
import BottomMenu from '../components/nav/BottomMenu';



const GPS = () => {

  let navigate = useNavigate()

  const { isOnline, isOffline, error } = useIsOnline();

  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const checkPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
        setHasPermission(permissionStatus.state === 'granted');
      } catch (error) {
        console.error('Error checking geolocation permission:', error);
      }
    };

    checkPermission();
    
  }, []);

  


    const {
        coords,
        getPosition,
        isGeolocationAvailable,
        isGeolocationEnabled
    } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
        suppressLocationOnMount: true

    });

 
    if(hasPermission === true) {
     
      getPosition()
    }

   const handlePosition =  () =>{

    
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            // Success: The user granted permission and the position is retrieved
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            
            // Do something with the latitude and longitude values
            console.log("Latitude:", latitude);
            console.log("Longitude:", longitude);
            navigate('/')
          },
          function(error) {
            // Error: The user denied permission or an error occurred
            switch(error.code) {
              case error.PERMISSION_DENIED:
                console.log("Geolocation permission denied.");
                break;
              case error.POSITION_UNAVAILABLE:
                console.log("Geolocation information is unavailable.");
                break;
              case error.TIMEOUT:
                console.log("Geolocation request timed out.");
                break;
              default:
                console.log("An unknown error occurred.");
                break;
            }
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
      

   }

   
  
  return (
    <>
      <Header />
      <div className='container p-4'>
              <h6 >
              <i class="fa-solid fa-location-crosshairs"></i> {gps.titulo}
              </h6>
              <button className='btn btn-entrega mt-3' onClick={handlePosition} style={{fontSize:'12px'}}>
            
              {hasPermission ? coords && <>
                          Tu ubicación actual: {coords.latitude.toFixed(4)} , {coords.longitude.toFixed(4)}
                  </> : !isGeolocationAvailable ? (
                  <div>{gps.texto1}</div>
                          ): !isGeolocationEnabled ? (
                            <div>Habilitar geolocalización</div>
                        ) : coords ? (
                  <>
                          {gps.texto3} {coords.latitude.toFixed(4)} , {coords.longitude.toFixed(4)}
                  </>
              ) : (
                  <div >{gps.texto2} </div>
              )}
              </button>
              <p className='text-justify mt-3'>
              {gps.texto4}

              </p>
              <p className='text-justify'>
                  
              {gps.texto5}<button className='ml-2 btn btn-secundary'>
              {browserName}</button> 
              </p>
      </div>
      <div className='navSubmenu fixed-bottom'>

        <div class="btn-group" role="group"  style={{width:'100%'}} aria-label="Basic example">
            <Link to='/'  style={{width:'100%'}} class="btn btn-submenu"><i class="fa-solid fa-house"></i></Link>
            {!coords ? ( <Link to='/gps'  style={{width:'100%'}} class="btn btn-submenu"><i class="fa-solid fa-location-dot"></i></Link>):(<Link to='/gps' style={{width:'100%'}} class="btn btn-submenuSuccess"><i class="fa-solid fa-location-dot"></i></Link>)}
            
            
            {
                isOnline ? <button style={{width:'100%'}} class="btn btn-submenuSuccess"><i class="fa-solid fa-signal"></i></button> : <button  style={{width:'100%'}} class="btn btn-submenu"><i class="fa-solid fa-signal"></i></button>
            }
            
            <Link to='/info'  style={{width:'100%'}} class="btn btn-submenu"><i class="fa-solid fa-info"></i></Link>
        </div>
        </div>
    
    </>
  )
}

export default GPS